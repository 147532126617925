// src/pages/FineFoods.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/theme.css';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/finefoods.css';

import chefImage from '../assets/images/fine_pan.webp'; // Placeholder for chef's image
import drinksPartnerImage from '../assets/images/fine_pan.webp'; // Placeholder for drinks partner image

const FineFoods = () => {
    const chefEmail = "chef@example.com"; // Replace with actual email

    const handleEmailChef = () => {
        window.location.href = `mailto:${chefEmail}`;
    };

    return (
        <div>
            <Header />
            <section className="hero-section" role="banner">
                <div className="container text-center">
                    <h1>Fine Foods</h1>
                    <p>Experience the best in gourmet cuisine with our award-winning chef and luxury drinks partner.</p>
                </div>
            </section>

            <section className="chef-section py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={chefImage} alt="Our Chef" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Meet Our Chef</h2>
                            <p>Our renowned chef has crafted a menu that celebrates the exquisite taste of truffles, complemented by fine dining and luxury drinks.</p>
                            <div className="social-links mb-3">
                                <a href="https://chefwebsite.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fas fa-globe"></i></a>
                                <a href="https://twitter.com/chef" target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-twitter"></i></a>
                                <a href="https://facebook.com/chef" target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-facebook"></i></a>
                                <a href="https://instagram.com/chef" target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-instagram"></i></a>
                            </div>
                            <button className="btn btn-success" onClick={handleEmailChef}>Contact the Chef</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="menu-section py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-4">Our Menu</h2>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <h3>Fine Dining Experience</h3>
                            <p>Savor a multi-course meal featuring truffle-infused dishes paired with champagne.</p>
                            <ul>
                                <li><strong>Starter:</strong> Truffle Soup (V, GF) <small>(contains dairy)</small></li>
                                <li><strong>Main:</strong> Truffle Risotto (V, GF) <small>(contains dairy)</small></li>
                                <li><strong>Dessert:</strong> Truffle Chocolate Fondant (V, GF) <small>(contains dairy, nuts)</small></li>
                            </ul>
                            <button className="btn btn-primary mt-2">Add Fine Dining Experience to Cart</button>
                        </div>
                        <div className="col-md-6 mb-4">
                            <h3>Normal Catering</h3>
                            <p>Enjoy a selection of truffle dishes that cater to all tastes and dietary needs.</p>
                            <ul>
                                <li><strong>Starter:</strong> Truffle Salad (V, GF, DF) <small>(contains nuts)</small></li>
                                <li><strong>Main:</strong> Truffle Pasta (V, GF) <small>(contains gluten, dairy)</small></li>
                                <li><strong>Dessert:</strong> Truffle Ice Cream (V, GF) <small>(contains dairy)</small></li>
                            </ul>
                            <button className="btn btn-primary mt-2">Add Normal Catering to Cart</button>
                        </div>
                    </div>
                    <p className="text-center mt-4">We cater to various dietary requirements. Please inform us of any specific needs.</p>
                </div>
            </section>

            <section className="drinks-partner-section py-5">
                <div className="container text-center">
                    <h2>Our Luxury Drinks Partner</h2>
                    <img src={drinksPartnerImage} alt="Luxury Drinks Partner" className="img-fluid rounded mb-4" />
                    <p>Enjoy exquisite drinks from our luxury partner, perfectly paired with our gourmet meals.</p>
                    <a href="https://drinks-partner.com" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Visit Our Drinks Partner</a>
                </div>
            </section>

            <section className="cooking-demos-section py-5 bg-light">
                <div className="container text-center">
                    <h2>Cooking Demo Stands</h2>
                    <p>Learn how to cook with truffles from our expert chefs throughout the day at our cooking demo stands. Watch live demonstrations and take away recipes to try at home.</p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default FineFoods;
