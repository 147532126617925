import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import QuantitySelector from '../components/QuantitySelector';
import { CartContext } from '../context/CartContext';
import axios from 'axios'; // Import axios for API requests
import '../styles/pages/cart.css';

const Cart = () => {
    const { cartItems, increaseQuantity, decreaseQuantity } = useContext(CartContext);

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    // Function to handle the checkout process
    const handleCheckout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
                cart: cartItems
            });

            const { url } = response.data;

            // Redirect to Stripe Checkout
            window.location.href = url;
        } catch (error) {
            console.error('Error during checkout:', error);
            alert('There was an error initiating the checkout process. Please try again later.');
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center">Your Cart</h2>
            {cartItems.length > 0 ? (
                <div className="cart-items">
                    {cartItems.map((item, index) => (
                        <div key={index} className="cart-item">
                            <img src={item.images[0]} alt={item.name} className="cart-item-image" />
                            <div className="cart-item-details">
                                <h5>{item.name}</h5>
                                <p>Total: £{(item.price * item.quantity).toFixed(2)}</p>
                            </div>
                            <QuantitySelector
                                productId={item.id}
                                quantity={item.quantity}
                                onIncrease={() => increaseQuantity(item.id)}
                                onDecrease={() => decreaseQuantity(item.id)}
                            />
                        </div>
                    ))}
                    <div className="cart-total">
                        <h4>Total: £{totalPrice.toFixed(2)}</h4>
                    </div>
                    <button className="btn btn-primary mt-4" onClick={handleCheckout}>Checkout</button> {/* Attach the checkout handler */}
                </div>
            ) : (
                <p className="text-center">Your cart is empty. Continue Hunting For <Link to="/tickets">Truffle Tickets...</Link></p>
            )}
        </div>
    );
};

export default Cart;
