import React from 'react';

const Credits = () => {
    return (
        <div className="container mt-4">
            <h1>Credits</h1>
            <p>We would like to thank the following individuals for their contributions:</p>
            <ol className="credits-list">
                <li>Loshani Wickramasinghe Event photos</li>
                <li>Michelle Wallis Event and Dog Championship photos</li>
                <li>Lesley Burdette Main page photo of Warts</li>
                <li>Emma Clifford Logo Design</li>
            </ol>
        </div>
    );
};

export default Credits;
