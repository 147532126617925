// src/pages/HaveAGo.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/theme.css';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/haveago.css';

import truffleHoundTrainingImg from '../assets/images/competition_dog.webp'; // Placeholder for training image
import bestDressedPoochImg from '../assets/images/competition_dog.webp'; // Placeholder for best dressed pooch image
import handsomeHoundImg from '../assets/images/competition_dog.webp'; // Placeholder for most handsome hound image

const HaveAGo = () => {
    return (
        <div>
            <Header />
            <section className="hero-section" role="banner">
                <div className="container text-center">
                    <h1>Have a Go Arena</h1>
                    <p>Bring your dog and see if they have what it takes to find a truffle in our "Have a Go" arena. Whether you're a professional or just curious, there’s fun for every dog and their owner!</p>
                </div>
            </section>
            
            <section className="training-section py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={truffleHoundTrainingImg} alt="Truffle Hound Training" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Truffle Hound Training</h2>
                            <p>Our experts will provide hands-on training sessions to help you and your dog learn the basics of truffle hunting. This is a great opportunity for your dog to develop their nose for truffles and have a lot of fun in the process.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="awards-section py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-4">Fun Awards and Prizes</h2>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="card">
                                <img src={bestDressedPoochImg} className="card-img-top" alt="Best Dressed Pooch" />
                                <div className="card-body">
                                    <h5 className="card-title">Best Dressed Pooch</h5>
                                    <p className="card-text">Get creative and dress up your dog for a chance to win the "Best Dressed Pooch" award. Show off your dog’s style and personality!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card">
                                <img src={handsomeHoundImg} className="card-img-top" alt="Most Handsome Hound" />
                                <div className="card-body">
                                    <h5 className="card-title">Most Handsome Hound</h5>
                                    <p className="card-text">Is your dog the most handsome in the land? Enter them in the "Most Handsome Hound" competition and let them shine.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card">
                                <img src={handsomeHoundImg} className="card-img-top" alt="Truffle Finder" />
                                <div className="card-body">
                                    <h5 className="card-title">Truffle Finder</h5>
                                    <p className="card-text">Let your dog have a go at finding hidden truffles and win amazing prizes. This award goes to the dogs that can sniff out the most truffles in the arena.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="prizes-section py-5">
                <div className="container text-center">
                    <h2>Prizes and Fun Activities</h2>
                    <p>In addition to the glory of winning, there are fantastic prizes to be won in each category. Enjoy a day of fun activities designed for both dogs and their owners. Don't miss the excitement in the "Have a Go" arena!</p>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default HaveAGo;
