import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { account, databases, appwriteConfig } from '../lib/Appwrite';
import { ID } from 'appwrite';

// Create React context to be used as AuthContext
const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  // State variables to manage user data, user type, purchases, and loading state
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState('guest');
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch user data and purchases when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      console.log('Fetching current user... (from AuthContext)');
      try {
        const currentSession = await account.getSession('current');
        if (currentSession) {
          try {
            const currentUser = await account.get();
            console.log('User fetched successfully:', currentUser);
            setUser(currentUser);

            const userType = currentUser.labels && currentUser.labels[0] ? currentUser.labels[0] : 'guest';
            console.log('User type:', userType);
            setUserType(userType);

            try {
              const userPurchases = await fetchUserPurchases(currentUser.$id);
              setPurchases(userPurchases);
            } catch (error) {
              console.error('Failed to fetch user purchases:', error);
              setPurchases([]);
            }
          } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
            setUserType('guest');
            setPurchases([]);
          }
        } else {
          throw new Error('No active session found');
        }
      } catch (error) {
        console.error('No active session');
        setUser(null);
        setUserType('guest');
        setPurchases([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // Fetch user's purchases from the database
  const fetchUserPurchases = async (userId) => {
    try {
      const purchases = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.ticketCollectionId,
        [`userId=${userId}`]
      );
      return purchases.documents;
    } catch (error) {
      console.error('Failed to fetch user purchases:', error);
      return [];
    }
  };

  // Log in the user with email and password
  const login = async (email, password) => {
    try {
      await account.createEmailPasswordSession(email, password);
    } catch (error) {
      console.error('Failed to create email session:', error);
      throw error;
    }

    let currentUser;
    try {
      currentUser = await account.get();
      setUser(currentUser);
    } catch (error) {
      console.error('Failed to fetch user after login:', error);
      throw error;
    }

    try {
      const userType = currentUser.labels && currentUser.labels[0] ? currentUser.labels[0] : 'guest';
      setUserType(userType);
    } catch (error) {
      console.error('Failed to determine user type:', error);
      throw error;
    }

    try {
      const userPurchases = await fetchUserPurchases(currentUser.$id);
      setPurchases(userPurchases);
    } catch (error) {
      console.error('Failed to fetch user purchases:', error);
      throw error;
    }
  };

  // Sign up a new user and log them in
  const signup = async (email, password, name, userType = 'guest', tickets = []) => {
    const userId = uuidv4().replace(/-/g, ''); // Generate a valid unique identifier
    try {
      console.log('Creating user account...');
      await account.create(userId, email, password, name, { labels: [userType] });
      console.log('User account created successfully.');
    } catch (error) {
      console.error('Failed to create user account:', error);
      throw error;
    }

    try {
      console.log('Creating user document...');
      await databases.createDocument(
        appwriteConfig.databaseId,
        appwriteConfig.userCollectionId,
        ID.unique(),
        {
          createdAt: new Date().toISOString(),
          accountId: userId,
        },
      );
      console.log('User document created successfully.');
    } catch (error) {
      console.error('Failed to create user document:', error);
      throw error;
    }

    try {
      await login(email, password);
    } catch (error) {
      console.error('Failed to login after signup:', error);
      throw error;
    }
  };

  // Log out the current user
  const logout = async () => {
    try {
      await account.deleteSession('current');
      setUser(null);
      setUserType('guest');
      setPurchases([]);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Provide the AuthContext values to the rest of the app
  return (
    <AuthContext.Provider value={{ user, userType, purchases, loading, login, signup, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
