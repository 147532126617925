//src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Melissa from './pages/Melissa';
import Tickets from './pages/Tickets';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Credits from './pages/Credits';
import Competition from './pages/Competition';
import Judges from './pages/Judges';
import Sponsors from './pages/Sponsors';
import HaveAGo from './pages/HaveAGo';
import FineFoods from './pages/FineFoods';
import Exhibitors from './pages/Exhibitors';
// import Experts from './pages/Experts';
import Cart from './pages/Cart';
import UserPortal from './userPortal/pages/UserPortal';
import Login from './pages/Login';
import Signup from './pages/Signup';
import PasswordResetRequest from './pages/PasswordReset';
import PasswordReset from './pages/PasswordReset';
import { CartProvider } from './context/CartContext';
import AuthProvider from './context/AuthContext';
import TicketProvider from './context/TicketContext';
import PrivateRoute from './lib/ProtectedRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <TicketProvider>
          <CartProvider>
            <div className="App">
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/melissa" element={<Melissa />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/credits" element={<Credits />} />
                <Route path="/exhibitors" element={<Exhibitors />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/sponsors" element={<Sponsors />} />
                <Route path="/haveAGo" element={<HaveAGo />} />
                <Route path="/fineFoods" element={<FineFoods />} />
                {/* <Route path="/experts" element={<Experts />} /> */}
                <Route path="/competition" element={<Competition />} />
                <Route path="/judges" element={<Judges />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/password-reset-request" element={<PasswordResetRequest />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/my-festival" element={
                  <PrivateRoute>
                    <UserPortal />
                  </PrivateRoute>
                } />
              </Routes>
              <Footer />
            </div>
          </CartProvider>
        </TicketProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
