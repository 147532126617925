// src/pages/Sponsors.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/theme.css';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/sponsors.css';

import mainSponsorImg from '../assets/images/competition_dog.webp'; // Main sponsor image
import dogShowSponsorImg from '../assets/images/competition_dog.webp'; // Dog show sponsor image

const sponsors = [
    {
        name: 'Main Sponsor',
        image: mainSponsorImg,
        description: 'Our main sponsor is a leading company in gourmet food products, supporting the truffle festival and sustainable foraging.',
        website: 'https://mainsponsor.com',
        socials: {
            facebook: 'https://facebook.com/mainsponsor',
            twitter: 'https://twitter.com/mainsponsor',
            instagram: 'https://instagram.com/mainsponsor',
        },
        articles: [
            { title: 'Main Sponsor - Leading the Way in Gourmet Food', link: '/blog/mainsponsor' }
        ]
    },
    {
        name: 'Dog Show Sponsor',
        image: dogShowSponsorImg,
        description: 'The Dog Show sponsor is a renowned pet care brand dedicated to the health and happiness of dogs.',
        website: 'https://dogshowsponsor.com',
        socials: {
            facebook: 'https://facebook.com/dogshowsponsor',
            instagram: 'https://instagram.com/dogshowsponsor',
        },
        articles: [
            { title: 'Dog Show Sponsor - Keeping Dogs Happy and Healthy', link: '/blog/dogshowsponsor' }
        ]
    }
];

const Sponsors = () => {
    return (
        <div>
            <Header />
            <section className="hero-section" role="banner">
                <div className="container text-center">
                    <h1>Our Sponsors</h1>
                    <p>We are grateful to our sponsors who make the Great British Truffle Festival possible. Their support helps us celebrate the world of truffles in a sustainable and inclusive way.</p>
                </div>
            </section>
            
            <section className="main-sponsor py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={sponsors[0].image} alt={sponsors[0].name} className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>{sponsors[0].name}</h2>
                            <p>{sponsors[0].description}</p>
                            <div className="social-links">
                                {sponsors[0].socials.facebook && <a href={sponsors[0].socials.facebook} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-facebook"></i></a>}
                                {sponsors[0].socials.twitter && <a href={sponsors[0].socials.twitter} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-twitter"></i></a>}
                                {sponsors[0].socials.instagram && <a href={sponsors[0].socials.instagram} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-instagram"></i></a>}
                            </div>
                            <div className="article-links">
                                {sponsors[0].articles.map((article, index) => (
                                    <p key={index}><a href={article.link} className="text-decoration-none">{article.title}</a></p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="event-sponsors py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-4">Event Sponsors</h2>
                    <div className="row">
                        {sponsors.slice(1).map((sponsor, index) => (
                            <div key={index} className="col-md-4 mb-4">
                                <div className="card h-100">
                                    <img src={sponsor.image} className="card-img-top" alt={sponsor.name} />
                                    <div className="card-body">
                                        <h5 className="card-title">{sponsor.name}</h5>
                                        <p className="card-text">{sponsor.description}</p>
                                        <div className="social-links">
                                            {sponsor.socials.facebook && <a href={sponsor.socials.facebook} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-facebook"></i></a>}
                                            {sponsor.socials.instagram && <a href={sponsor.socials.instagram} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-instagram"></i></a>}
                                        </div>
                                        <div className="article-links">
                                            {sponsor.articles.map((article, idx) => (
                                                <p key={idx}><a href={article.link} className="text-decoration-none">{article.title}</a></p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Sponsors;
