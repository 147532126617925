import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/competition.css';
import competitionDog from '../assets/images/competition_dog.webp';
import first from '../assets/images/first.png'; 
import second from '../assets/images/second.png'; 
import third from '../assets/images/third.png'; 

const Competition = () => {
    return (
        <div className="competition-page">
            <section className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">The Great British Truffle Hound Competition</h2>
                    <p className="text-center mb-4">Join us for an exciting and fun-filled Truffle Hound Competition! This event celebrates the incredible skills of truffle-hunting dogs and their handlers.</p>

                    <h3 className="text-center mb-4">Gold Winners</h3>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={first} className="img-fluid" alt="Truffle Hound" />
                        </div>
                    </div>
                    <h3 className="text-center mb-4">Silver Winners</h3>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={second} className="img-fluid" alt="Truffle Hound" />
                        </div>
                    </div>
                    <h3 className="text-center mb-4">Bronze Winners</h3>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={competitionDog} className="img-fluid" alt="Truffle Hound" />
                        </div>
                        <div className="col-md-4 mb-3">
                            <img src={third} className="img-fluid" alt="Truffle Hound" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Who Can Enter?</h3>
                            <p>Evaluate your skills to get you into the right entry level via the form.</p>
                            <p>Anyone can enter as long as they have had some experience or training. We will help you evaluate your level of entry and get you in the one that fits your ability. I’m sure most of you have a rough idea what to aim for but if not look at the rules and it might help you determine what is achievable. The link below will take you to the questionnaire.</p>
                            
                            <Link to="/signup?userType=competitor&redirectPage=/competitor-registration" className="btn btn-primary">
                                Register Now
                            </Link>
                        </div>

              
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Competition Rules</h3>
                            <p>Competitors will search for truffles in designated areas with their dogs. Points are awarded for correct finds, with penalties for incorrect calls or rule infractions. Detailed rules are available <a href="/competition-rules.pdf">here</a>.</p>
                            <p>
                                BRONZE
                                <br /> 5 baits - 5 minute search
                                <br /> All above ground
                                <br /> 1 real truffle, 4 truffle oil
                                <br /><br />
                                SILVER
                                <br /> 10 baits - 10 minutes to search.
                                <br /> Mixture of above ground and below ground baits.
                                <br /> 3 real truffle, 7 truffle oil.
                                <br /><br />
                                GOLD
                                <br /> 10 baits - 10 minutes to search. Competitors do not know how many baits there are.
                                <br /> All baits will be below ground.
                                <br /> 5 real truffle, 5 truffle oil.
                                <br /><br />
                                Areas are 20m x 30m
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Why Take part?</h3>
                            <p>This unique truffle hound competition at the Great British Truffle Festival  is inspired by the late Marrian Dean, who established the very first truffle dog competition in the UK over 15 years ago. Marrian's pioneering spirit and dedication to truffle hunting have laid the foundation for this exciting event. Our competition aims to honour her legacy by bringing together truffle hunters from all over the country. It's an opportunity for participants to showcase their skills, share their experiences, and celebrate the incredible abilities of their truffle-hunting dogs. Whether you're a seasoned competitor or a curious spectator, this event promises to be a fascinating and rewarding experience for all. Join us in continuing on the tradition and and be a part of the vibrant truffle hunting community.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Junior Competition</h3>
                            <p>We have a junior competition with only 10 spaces available for under sixteens.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>What are the Prizes?</h3>
                            <p>The winner of Gold first place receives the main prize of: “A Certificate Recognised Within the Industry” for future work. A rosette with 1st in Gold plus your score sheet. A selection of truffle products from our sponsor Truffle Hunter. A mushroom foray with Melissa for 2 people.</p>
                            <p>The winner of Silver first place: Certificate of placing and entry plus your score sheet. A rosette with 1st in Silver and a selection of truffle products from our sponsor Truffle Hunter. A Mushroom foray with Melissa for 1 person.</p>
                            <p>The winner of Bronze first place: Certificate of placing and entry plus your score sheet. A rosette with 1st in Bronze and a bottle of truffle oil from our sponsor Truffle Hunter. A Mushroom Foray with Melissa for 1 person.</p>
                            <p>Second place in all categories: Certificate of placing and entry plus your score sheet. A rosette with 2nd placement. A selection of truffle products from our sponsor Truffle Hunter.</p>
                            <p>Third place in all categories: Certificate of placing and entry. A rosette with 3rd placement plus your scoresheet. A bottle of truffle oil from our sponsor Truffle Hunter.</p>
                            <p>All winners in 1st, 2nd and 3rd categories will be announced on the website and recognised for their achievements every year unless you have any objections.</p>
                            <p>All remaining competitors get a packet of Truffle Hunter Crisps. A certificate of placing and entry, plus your score sheet.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Using Truffle Oil to train your hound</h3>
                            <p>Truffle Hunter has been our generous sponsor for two years, this will be their third year. Thank you Truffle Hunter for your continued support.</p>
                            <p>I always recommend using their oil for culinary use if you like truffle oil. I also use it for dog training and always promote their oil as it has had extraordinary results, aromatic and does the job very well. In particular the British version just because I’m stupidly puritanical about it but to be honest it would not make any difference what species of truffle oil you use! Trained on one truffle species they go on to find them all; it is a fallacy that you have to train per species!!</p>
                            <p>The aromas found in truffle oil replicate the phenolic compounds found in truffles, in particular sulphurs and probably aiming for androstenone at a guess as these two chemicals are where lies the attraction to both humans, pigs and all mammals actually.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>How are the dogs Judged?</h3>
                            <p>Dogs are judged by how many truffle baits they find according to the level they are in, they are awarded points and according to the time they find them compared to other competitors. There are points that can be deducted according to undesirable behavior, not in control, persistent digging and urination.</p>
                            <p>A Big Thank You If You Use A Belly Band!</p>
                            <p>But there is still a risk of contamination through odour or spillage if the dog cocks their leg.</p>
                            <p>The belly band is a courtesy to all for trying to keep the area as clean and fair as possible and we are grateful for competitors who use them.</p>
                            <p>The act of cocking a leg/attempting to mark will still be penalised as the dog has carried out the act of marking.</p>
                            <p>When marking, the dog is not concentrating on the task at hand and not working, therefore the behaviour in itself can be justified to be penalised.</p>
                            <p>Please see competition rules for full description above.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>How Can we enter?</h3>
                            <p>You can enter by using this link on your phone or computer; also via facebook on our Great British Truffle Festival Page or on our website when the page has been completed, work in progress!</p>
                            <a href="https://forms.gle/ruVskGu79sbB15SVA">Here's the form</a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mb-4 card">
                            <h3>Which level is right for us??</h3>
                            <p>A questionnaire form is attached to the application to enter the competition which will help us determine your ability and help us get you into the correct level, either Bronze for beginners, Silver for intermediates and Gold the highest category. If you are not happy with your placement we can always discuss further with you. Please find the questionnaire and form below.</p>
                            <a href="https://forms.gle/ruVskGu79sbB15SVA">https://forms.gle/ruVskGu79sbB15SVA</a>
                        </div>
                    </div>

                    
                </div>
            </section>
        </div>
    );
};

export default Competition;
