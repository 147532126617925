// src/lib/appwrite.js
import { Client, Account, Databases } from 'appwrite';

export const appwriteConfig = {
    endpoint: process.env.REACT_APP_APPWRITE_ENDPOINT,
    projectId: process.env.REACT_APP_APPWRITE_PROJECT_ID,
    databaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    userCollectionId: process.env.REACT_APP_APPWRITE_USER_COLLECTION_ID,
    ticketCollectionId: process.env.REACT_APP_APPWRITE_TICKET_COLLECTION_ID,
};

const client = new Client();

client
    .setEndpoint(appwriteConfig.endpoint)
    .setProject(appwriteConfig.projectId)

export const account = new Account(client);
export const databases = new Databases(client);
