import React from 'react';
import '../styles/pages/success.css'; // Import the CSS file

function Success() {
    return (
        <div className="success-container">
            <div className="success-content">
                <h1 className="success-heading">Payment Successful!</h1>
                <p className="success-message">Thank you! Can't wait to see you on the day. We'll email you your tickets shortly.</p>
                <img
                    src="https://via.placeholder.com/150" // Replace with a relevant success image or icon URL
                    alt="Success"
                    className="success-image"
                />
            </div>
        </div>
    );
}

export default Success;
