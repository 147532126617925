import React from 'react';
// import { Link } from 'react-router-dom';
import haveAGo from '../assets/images/960x640/competition_dog.webp';
import fineFoods from '../assets/images/960x640/fine_pan.webp';
import competition from '../assets/images/960x640/truffle_winner.webp';
import expertTalks from '../assets/images/960x640/workshops.webp';
import '../styles/sections/whatToExpectSection.css'

const WhatToExpectSection = () => (
    <section id="the-basics">
        <h2>What To Expect</h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">

            <div className="col">
                {/* <Link to="/haveAGo" className="card h-100"> */}
                    <img src={haveAGo} className="card-img-top" alt="Canine Companionship" />
                    <div className="card-body">
                        <h3 className="card-title">🐶 Have-A-Go Arena 🐾</h3>
                        <p className="card-text">See if your dog has got the nose 🐕👃 or not! If not, don't worry, you could still win the Best Looking Hound Award 🏆among other prizes.</p>
                    </div>
                {/* </Link> */}
            </div>

            <div className="col">
                {/* <Link to="/fineFoods" className="card h-100"> */}
                    <img src={fineFoods} className="card-img-top" alt="Gourmet Cooking Demonstration with Truffles" />
                    <div className="card-body">
                        <h3 className="card-title">🍽️ Fine Foods Experience 🥘</h3>
                        <p className="card-text">Join us at the table, or savour the flavours with our cooking demos!</p>
                    </div>
                {/* </Link> */}
            </div>

            <div className="col">
                {/* <Link to="/competition" className="card h-100"> */}
                    <img src={competition} className="card-img-top" alt="Truffle Hunting Dog in Action" />
                    <div className="card-body">
                        <h3 className="card-title">🏆The Great British Truffle Hound Competition🏆</h3>
                        <p className="card-text">Enter at Bronze, Silver or Gold level with your truffle hound!</p>
                    </div>
                {/* </Link> */}
            </div>

            <div className="col">
                {/* <Link to="/expertTalks" className="card h-100"> */}
                    <img src={expertTalks} className="card-img-top" alt="Ecological and scientific thoughts being shared by experts." />
                    <div className="card-body">
                        <h3 className="card-title">🎤 Expert Talks 🔬</h3>
                        <p className="card-text">Dive deeper with insights from the forefront of the truffle world and hands-on workshops.</p>
                    </div>
                {/* </Link> */}
            </div>

        </div>
    </section>
);

export default WhatToExpectSection;
