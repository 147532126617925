// src/pages/PasswordReset.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { account } from '../lib/Appwrite';

const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const userId = query.get('userId');
  const secret = query.get('secret');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await account.updateRecovery(userId, secret, password);
      setMessage('Password reset successful.');
    } catch (error) {
      setMessage('Failed to reset password.');
      console.error('Password reset failed:', error);
    }
  };

  return (
    <div className="auth-container">
      <h1>Reset Password</h1>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default PasswordReset;
