import React from 'react';

import HeroSection from '../sections/HeroSection';
import AboutSection from '../sections/AboutSection';
import WhatToExpectSection from '../sections/WhatToExpectSection';
import CtaSection from '../sections/CtaSection';
import OurStorySection from '../sections/OurStorySection';
import HelpfulInfoSection from '../sections/HelpfulInfoSection';
import FaqSection from '../sections/FaqSection';

import heroLogo from '../assets/images/tgbtf_logo_white_500.webp';

const handleSubscribeClick = () => {
  const subscribeModal = new window.bootstrap.Modal(document.getElementById('subscribeModal'));
  subscribeModal.show();
};

const Home = () => (
  <div>
    <HeroSection 
      title="Unearth the Magic"
      description="November 16th, 2024"
      image={heroLogo}
      showButton={true}
      buttonText="Subscribe"
      buttonTarget="#subscribeModal"
    />
    <div className="flex-container" role="main">
      <AboutSection />
      <WhatToExpectSection />
      <CtaSection 
        id="cta1"
        title="Join the Movement"
        description="Secure your early bird spot and be part of preserving the legacy and delight of British truffles. Join the celebration of our natural heritage and culinary culture."
        linkText="Get Your Tickets"
        linkUrl="/tickets"
        onClick={() => window.location.href = '/tickets'}
      />
      <OurStorySection />
      <CtaSection 
        id="cta2"
        title="Lineup - Be the First to Know!"
        description="Don't miss out on our exciting lineup reveal. Subscribe to our newsletter now and get exclusive access to the heart of the truffle celebration."
        linkText="Subscribe"
        linkUrl="#subscribeModal"
        onClick={handleSubscribeClick}
      />
      <HelpfulInfoSection />
      <FaqSection />
    </div>
  </div>
);

export default Home;
