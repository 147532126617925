import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FaShoppingCart } from 'react-icons/fa';
import { CartContext } from '../context/CartContext';
import { useAuthContext } from '../context/AuthContext';
import headerLogo from '../assets/images/header_logo.webp';
import loggedInAvatar from '../assets/images/logged_in.png';
import loggedOutAvatar from '../assets/images/logged_out.png';
import '../styles/components/header.css';

function Header() {
    const { getCartCount } = useContext(CartContext);
    const { user, logout } = useAuthContext();
    const cartCount = getCartCount();
    const location = useLocation();
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const [isAvatarDropdownOpen, setIsAvatarDropdownOpen] = useState(false);

    useEffect(() => {
        // Close the mobile menu when the route changes
        setIsNavbarCollapsed(true);
        setIsAvatarDropdownOpen(false);
    }, [location.pathname]);

    const toggleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };

    const toggleAvatarDropdown = () => {
        setIsAvatarDropdownOpen(!isAvatarDropdownOpen);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top color-cycle" role="navigation">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={headerLogo} alt="Logo" className="navbar-logo" />
                        <div className="brand-text-wrapper">
                            <span className="brand-text">The Great British Truffle Festival</span>
                        </div>
                    </Link>

                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleNavbar}
                        aria-controls="navbarNav"
                        aria-expanded={!isNavbarCollapsed}
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`} id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </Link>
                                <ul className="dropdown-menu color-cycle" aria-labelledby="aboutDropdown">
                                    <li><HashLink className="dropdown-item" smooth to="/#about">What is the Festival?</HashLink></li>
                                    <li><HashLink className="dropdown-item" smooth to="/#our-story">Our Story</HashLink></li>
                                    <li><HashLink className="dropdown-item" smooth to="/#faq">FAQs</HashLink></li>
                                    <li><Link className="dropdown-item" to="/melissa">Our Founder, Melissa</Link></li>
                                </ul>
                            </li>
                            
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="festivalDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    The Festival
                                </Link>
                                <ul className="dropdown-menu color-cycle" aria-labelledby="festivalDropdown">
                                    <li><Link className="dropdown-item" to="/judges">The Judging Panel</Link></li>
                                    <li><Link className="dropdown-item" to="/competition">The Truffle Dog Competition</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="https://blog.thegreatbritishtrufflefestival.co.uk/">Blog</a></li>
                            <li className="nav-item"><Link className="nav-link" to="/tickets">Tickets</Link></li>
                        </ul>
                    </div>
                    <div className="navbar-icons">
                        <Link className="nav-link" to="/cart">
                            <FaShoppingCart size={25} />
                            {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
                        </Link>
                        <div className="nav-item dropdown">
                            <img
                                src={user ? loggedInAvatar : loggedOutAvatar}
                                alt="Avatar"
                                className="user-avatar nav-link dropdown-toggle"
                                id="avatarDropdown"
                                role="button"
                                onClick={toggleAvatarDropdown}
                                aria-expanded={isAvatarDropdownOpen}
                            />
                            <ul className={`dropdown-menu ${isAvatarDropdownOpen ? 'show' : ''}`} aria-labelledby="avatarDropdown">
                                {user ? (
                                    <>
                                        <li><Link className="dropdown-item" to="/my-festival">My Festival</Link></li>
                                        <li><button className="dropdown-item" onClick={logout}>Logout</button></li>
                                    </>
                                ) : (
                                    <>
                                        <li><Link className="dropdown-item" to="/login">Login/ Register</Link></li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
