// src/userPortal/pages/guests/Tickets.js
import React from 'react';
import { useAuthContext } from '../../../context/AuthContext';

const Tickets = () => {
  const { purchases } = useAuthContext();

  return (
    <div className="tickets-content">
      {purchases.length === 0 ? (
        <p>No tickets purchased yet.</p>
      ) : (
        purchases.map((purchase, index) => (
          <div key={index} className="ticket-item">
            <h3>{purchase.products.map(product => product.name).join(', ')}</h3>
            <p>Amount: {purchase.products.map(product => product.amount).join(', ')}</p>
            <p>Currency: {purchase.products.map(product => product.currency).join(', ')}</p>
            <p>Purchased at: {new Date(purchase.purchasedAt).toLocaleString()}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default Tickets;
