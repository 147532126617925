// mixpanelSetup.js
import mixpanel from 'mixpanel-browser';

mixpanel.init('ff7a63c2256fc3b9f9115e48310f1017', {
  debug: true,
  track_pageview: false,
  persistence: 'localStorage',
  ignore_dnt: true,
});

document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (event) {
    const target = event.target;
    if (target.classList.contains('btn')) {
      let eventName = 'Button Clicked';
      const buttonName = target.innerText.trim();
      let buttonLocation = 'Modal'; 
      
      if (target.classList.contains('subscriptionButton')) {
        eventName = 'Subscription CTA Clicked';
      } else if (target.classList.contains('waitlistButton')) {
        eventName = 'Waitlist CTA Clicked';
      } else {
        return;
      }
      
      const closestSection = target.closest('section');
      if (closestSection) {
        buttonLocation = closestSection.id;
      }

      console.log(`${eventName} {` +
        `Button Name: ${buttonName}, ` +
        `Button Location: ${buttonLocation}` +
      `}`);

      mixpanel.track(eventName, {
        'Button Name': buttonName,
        'Button Location': buttonLocation,
      });
    }
  });
});

export function submitForm(formType) {
  let formId, confirmationMessageId, form, confirmationMessage, formData, eventName;
  switch (formType) {
    case 'contact':
      formId = 'myForm';
      confirmationMessageId = 'confirmationMessage';
      eventName = 'Contact Form Submitted';
      break;
    case 'subscriber':
      formId = 'subscriberForm';
      confirmationMessageId = 'subscriberConfirmationMessage';
      eventName = 'Subscription Form Submitted';
      break;
    case 'waitlist':
      formId = 'waitlistForm';
      confirmationMessageId = 'waitlistConfirmationMessage';
      eventName = 'Waitlist Form Submitted';
      break;
    default:
      console.error('Unknown form type:', formType);
      return;
  }

  form = document.getElementById(formId);
  confirmationMessage = document.getElementById(confirmationMessageId);

  if (!form) {
    console.error('Form not found:', formId);
    return;
  }

  formData = new FormData(form);
  formData.set('formType', formType);

  const email = formData.get('email');
  const name = formData.get('name');
  const message = formData.get('message');

  fetch('https://script.google.com/macros/s/AKfycbzcZTvUEizWuXy8EUVH_WRMXe_J2w1OkAuCH7nLpWKBqvS5gWa8F3_RlG40C3-l5Wtn/exec', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);

      confirmationMessage.style.display = 'block';
      form.style.display = 'none';

      // Track the form submission event.
      mixpanel.track(eventName, {
        'Form Type': formType,
        'Email': email,
        'Name': name,
        'Message': message,
      });

      mixpanel.identify(email);
      mixpanel.people.set({
        '$email': email,
        '$name': name,
      });
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}
