import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import axios from 'axios';
import Header from '../components/Header';
import QuantitySelector from '../components/QuantitySelector';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/tickets.css';
import { CartContext } from '../context/CartContext'; // Import CartContext

const Tickets = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Tickets'); // Default to "Tickets"

    // Use the CartContext
    const { cartItems, addItemToCart, increaseQuantity, decreaseQuantity } = useContext(CartContext);

    // Define the categories based on the new product metadata setup
    const categories = ['Tickets', 'Competitors', 'Experiences'];

    const filterProducts = useCallback((category, allProducts = products) => {
        const filtered = allProducts.filter(product => product.metadata[category] === "true");
        setFilteredProducts(filtered);
    }, [products]);

    useEffect(() => {
        // Fetch products and their prices
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
                const productsData = response.data;

                // Fetch prices for each product
                const productsWithPrices = await Promise.all(
                    productsData.map(async (product) => {
                        try {
                            const priceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/prices/${product.default_price}`);
                            return {
                                ...product,
                                price: priceResponse.data.unit_amount / 100 // Convert from cents to currency unit
                            };
                        } catch (priceError) {
                            console.error('Error fetching price:', priceError);
                            return product; // Return the product without the price if an error occurs
                        }
                    })
                );

                setProducts(productsWithPrices);
                filterProducts(selectedCategory, productsWithPrices); // Initial filter based on default category
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [filterProducts, selectedCategory]); // Fetch products initially and when selectedCategory changes

    const handleCategoryChange = (category) => {
        setSelectedCategory(category); // Set the selected category
        filterProducts(category, products); // Filter products based on the new category
    };

    const getCartItemQuantity = (productId) => {
        const cartItem = cartItems.find(item => item.id === productId);
        return cartItem ? cartItem.quantity : 0;
    };

    return (
        <div>
            <Header />
            <div className="container mt-4">
                <h2 className="text-center">Tickets</h2>

                <div className="filter-section text-center mb-4">
                    <div className="filter-chips">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`chip ${selectedCategory === category ? 'selected' : ''}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="row">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((product, index) => (
                            <div key={index} className="col-md-4 mb-4">
                                <div className="card product-card">
                                    <img src={product.images[0]} alt={product.name} className="card-img-top" />
                                    <div className="card-body">
                                        <h5 className="card-title">{product.name}</h5>
                                        <p className="card-text">{product.description}</p>
                                        {product.price !== undefined ? (
                                            <p className="price">Price: £{product.price.toFixed(2)}</p>
                                        ) : (
                                            <p className="price">Price: Not available</p>
                                        )}
                                        {getCartItemQuantity(product.id) > 0 ? (
                                            <QuantitySelector
                                                productId={product.id}
                                                quantity={getCartItemQuantity(product.id)}
                                                onIncrease={() => increaseQuantity(product.id)}
                                                onDecrease={() => decreaseQuantity(product.id)}
                                            />
                                        ) : (
                                            <button className="btn btn-primary" onClick={() => addItemToCart(product)}>Add to Cart</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center">No tickets available in this category.</p>
                    )}
                </div>

                {/* Go to Cart Button */}
                <div className="text-center mt-4">
                    <Link to="/cart" className="btn btn-secondary">Go to Cart</Link>
                </div>
            </div>
        </div>
    );
};

export default Tickets;
