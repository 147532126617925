// src/components/QuantitySelector.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/quantitySelector.css';

const QuantitySelector = ({ productId, quantity, onAddToCart, onIncrease, onDecrease }) => {
    return (
        <div className="quantity-selector">
            {quantity > 0 ? (
                <>
                    <button onClick={() => onDecrease(productId)} className="quantity-button">-</button>
                    <span className="quantity-value">{quantity}</span>
                    <button onClick={() => onIncrease(productId)} className="quantity-button">+</button>
                </>
            ) : (
                <button onClick={() => onAddToCart(productId)} className="add-to-cart-button">Add to Cart</button>
            )}
        </div>
    );
};

QuantitySelector.propTypes = {
    productId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    onAddToCart: PropTypes.func.isRequired,
    onIncrease: PropTypes.func.isRequired,
    onDecrease: PropTypes.func.isRequired,
};

export default QuantitySelector;
