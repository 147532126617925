// src/context/TicketContext.js
import React, { createContext, useContext, useState } from 'react';
import { databases, appwriteConfig } from '../lib/Appwrite';
import { Query } from 'appwrite'; // Import Query from appwrite

const TicketContext = createContext();
export const useTicketContext = () => useContext(TicketContext);

const TicketProvider = ({ children }) => {
  const [tickets, setTickets] = useState([]);

  const fetchTickets = async (userId) => {
    try {
      const response = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.ticketCollectionId,
        [Query.equal('userId', userId)]
      );
      setTickets(response.documents);
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    }
  };

  return (
    <TicketContext.Provider value={{ tickets, fetchTickets }}>
      {children}
    </TicketContext.Provider>
  );
};

export default TicketProvider;
