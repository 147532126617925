// HeroSection.js
import React from 'react';
import '../styles/sections/heroSection.css';

const HeroSection = ({ title, description, image, showButton = false, buttonText, buttonTarget }) => (
  <section className="hero-section" role="banner">
    <div className="hero-content">
      {image && <img src={image} alt="Hero" className="hero-image" />}
      <div className="hero-text">
        <h1>{title}</h1>
        <p>{description}</p>
        {showButton && 
          <button className="btn btn-primary color-cycle subscriptionButton" data-bs-toggle="modal" data-bs-target={buttonTarget}>
            {buttonText}
          </button>
        }
      </div>
    </div>
  </section>
);

export default HeroSection;
