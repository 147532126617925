import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/melissa.css';
import melissaImg from '../assets/images/melissa.png';

const Melissa = () => {

    

    useEffect(() => {
        // Add RSS feed script when component mounts
        const scriptId = 'rssapp-wall-script';
        if (!document.getElementById(scriptId)) {
            addExternalScript('https://widget.rss.app/v1/wall.js', scriptId);
        }

        // Cleanup function to remove the script when component unmounts
        return () => {
            const script = document.getElementById(scriptId);
            if (script) {
                script.remove();
            }
        };
    }, []);

    const addExternalScript = (src, id) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.async = true;
        document.body.appendChild(script);
    };

    // Suppress the ResizeObserver loop limit exceeded error
    useEffect(() => {
        const observerErrorHandler = (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        window.addEventListener('error', observerErrorHandler);

        return () => {
            window.removeEventListener('error', observerErrorHandler);
        };
    }, []);

    return (
        <div>

            <div className="breadcrumb">
                <p><Link to="/">↩ Back To Main Page</Link></p>
            </div>

            <div className="profile-flex-container">
                <img src={melissaImg} height="200" alt="Melissa Waddingham" className="profile-pic" />
                <div className="mel-container">
                    <h1>Melissa Waddingham</h1>
                    <h5 className="brand-font">Founder</h5>
                    <p>
                        I have early memories of foraging with my grandfather in Champagne, France gathering Chanterelles. I was never aware that he secretly slipped out and came home with the odd truffle in his pocket, apparently only for special customers and family as he ran an auberge that was very popular with the locals for his sublime food. It was my father who told me about my Grandfather and his truffle antics, when I presented him with ten or eleven of my own! I found this instantly gratifying realizing that my passion truly was in the blood. My friends tell me that I have always foraged for something be it a fossil, flower, plant, piece of attractive wood but I would never come home alone, this is very true and I seemed to have a natural affinity to what was around me.
                    </p>
                    <p>
                        I am a self taught professional truffle hunter and wild mushroom picker. I did a forestry degree at Plumpton College in 2008 to support my already growing knowledge about fungi which was at that time an evolving hobby which grew into a passion for amateur mycology and what lay ahead in the quest for fungal and woodland knowledge.
                    </p>
                    <p>
                        In the spring and autumn months I run mushroom forays, truffle hunts, talks, courses and throughout the year I provide truffle hound training days for your truffle hound to be.
                    </p>
                    <p>
                        I also manage wild truffle woodlands for the sustainable production of truffle and give consultancy advice and offer fungi and truffle surveys for private woodland owners.
                    </p>
                    <p>
                        I was the original founder of The UK Truffle Festival and Dog Championships 2022-2023 and now I am pursuing a new role and founding the Great British Truffle Festival 2024 under new partnership with Michael Corcoran. A new venture which fills me with excitement for the next one and the future, especially as the last two were such a great success.
                    </p>
                </div>
            </div>
            
            <div id="rssapp-wall-container" style={{ height: 'auto', overflow: 'auto' }}>
                <rssapp-wall id="_zh5YUTsWwfTxi616"></rssapp-wall>
            </div>

        </div>
    );
}

export default Melissa;
